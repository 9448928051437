.form-container .btn {
  border-radius: 10px;
  height: 55px;
  font-size: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-control-check .MuiInput-underline:before,
.form-control-check .MuiInput-underline::after {
  border-color: var(--color-success) !important;
}

.form-control-error .MuiInput-underline:before,
.form-control-error .MuiInput-underline::after {
  border-color: var(--color-danger) !important;
}

.form-check-input {
  opacity: 0.5;
  background-color: var(--color-grey-light);
  width: 1.2rem;
  height: 1.2rem;
}
