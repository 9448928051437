@import 'colors';
@import 'form';
@import 'sidebar';
@import 'custom';
@import 'scroll-box.styles';
@import 'animation';
@import 'media';
@import 'bootstrap/scss/bootstrap.scss';

body {
  font-family: 'Roboto', sans-serif !important;
}
